<template>
    <transition name="slide-left" mode="out-in">
        <div class="settings--container" v-if="isLoaded" :class="{'scrolled' : $route.name === 'SettingsProfileView'}">
            <div class="nav-bar--container" v-if="isMobile">
                <div class="nav--item" @click="back">
                    <img class="back-img__button" src="../../assets/icons/back.svg">
                </div>
                <div class="nav--item">
                    <p class="text__title">{{$route.meta.title}}</p>
                </div>
                <div class="nav--item nav__transparent"></div>
            </div>

            <div class="router--container">
                <router-view></router-view>
            </div>
        </div>
    </transition>
</template>

<script>
    export default {
        name: "SettingsMainView",
        data(){
            return {
                isLoaded : false
            }
        },
        computed:{
            isMobile(){
                return screen.width < 992
            }
        },
        methods:{
            back(){
                if (this.$route.name === 'SettingsMainListView'){
                    this.$router.push({name : 'ParentView'})
                } else {
                    this.$router.push({name : 'SettingsMainListView'})

                }
            }

        },
        mounted() {
            this.isLoaded = true
        }
    }
</script>

<style scoped lang="scss">
    .settings--container{
        width: 100%;
        height: 100%;
        background-color: #f8f8f8;
        display: flex;
        flex-direction: column;

        &.scrolled{
            height: calc(100% + 120px);
        }


        .nav-bar--container{
            width: 100%;
            height: 20px;
            display: flex;
            justify-content: space-between;
            margin-top: 14px;
            align-items: center;

            .nav--item{
                height: 20px;
                .back-img__button{
                    width: 20px;
                    height: 20px;
                }


                .text__title{
                    font-family: Roboto, "sans-serif";
                    font-size: 17px;
                    letter-spacing: 0;
                    color: #222222;
                    line-height: 20px;
                }

            }

            .nav--item:first-child{
                margin-left: 20px;
            }

            .nav--item:last-child{
                margin-right: 20px;
            }

        }
    }


    .slide-left-enter-active,
    .slide-left-leave-active,
    .slide-right-enter-active,
    .slide-right-leave-active {
        transition-duration: .5s;
        transition-property: height, opacity, transform;
        transition-timing-function: cubic-bezier(0.55, 0, 0.1, 1);
        overflow: hidden;
    }

    .slide-left-enter,
    .slide-right-leave-active {
        opacity: 0;
        transform: translate(2em, 0);
    }

    .slide-left-leave-active,
    .slide-right-enter {
        opacity: 0;
        transform: translate(-2em, 0);
    }

</style>
